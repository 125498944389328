// src/components/QRContainer.jsx
import QRCode from 'qrcode.react';
import React from 'react';
import { useSelector } from 'react-redux';

const QRContainer = () => {
  const depositAddress = useSelector((state) => state.transaction.depositAddress);
  const network = useSelector((state) => state.transaction.network);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const coinName = selectedCoin?.name;

  return (
    <div className="qr-container">
      <QRCode value={depositAddress} size={180} level="H" bgColor="#fff" fgColor="#000" />
      <div className="info-container">
        <InfoRow label="Network" value={network} coinName={coinName}/>
      </div>
    </div>
  );
};

const InfoRow = ({ label, value, coinName}) => (
  <div className={`info-row-${label.toLowerCase()}`}>
    <div className="info-text">
      {label === 'Network' && <div className="info-label">Send <strong>Only</strong>:</div>}
      <div className="info-value">{`${value} ${label} ${coinName}`}</div>
    </div>
    <div className='icon network'/>
  </div>
);

export default React.memo(QRContainer);
