// src/components/Footer.jsx
import React from 'react';
import { useSelector } from 'react-redux';

const Footer = () => {
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);

  return (
  <div className="footer">
    {isAllowedDomain ? (
      <div className="footer_logo_global" />
    ) : (
      <>
        <p className="footer_text">This service is provided by</p>
        <div className="footer_logo" />
      </>
    )}
  </div>
);
};

export default Footer;