// src/App.js
import React, { useEffect, useRef, useState } from "react";
import "./App.scss";
import PageBox from "./components/PageBox";
import { useFetchCoins } from "./utils/useFetchCoins";
import useWebSocket from "./utils/useWebSocket";
import { wsEndpoints } from "./utils/wsUrls";
import { useDispatch } from "react-redux";
import {
  resetHostDomain,
  setHostDomain,
  setUserMagicAddress,
} from "./features/host/hostSlice";
import { getSearchParams } from "./utils/helpers";

function App() {
  const dispatch = useDispatch();
  const [wsEndpoint, setWsEndpoint] = useState(wsEndpoints.production);
  const { connect, disconnect } = useWebSocket(wsEndpoint);
  useFetchCoins();
  const loaded = useRef(null);

  useEffect(() => {
    console.log("App mounted");
    if (loaded.current) return;
    console.log("Loaded.current", loaded.current);
    loaded.current = true;

    const params = getSearchParams();
    const allowedOrigins = ["localhost:3002", "coinsexchange.com"];

    console.log("Received params from URL:", params);

    if (params.appDomain && allowedOrigins.includes(params.appDomain)) {
      console.log("Params from allowed origin:", params.appDomain);
      dispatch(setHostDomain(params.appDomain));
      if (params.userMagicAddress) {
        dispatch(setUserMagicAddress(params.userMagicAddress));
      }
      console.log("Received appDomain:", params.appDomain);
      console.log("Received userMagicAddress:", params.userMagicAddress);

      setWsEndpoint(
        params.appDomain === "localhost:3002" ||
          params.appDomain === "coinsexchange.com"
          ? wsEndpoints.globalProduction
          : wsEndpoints.production
      );
    }

    return () => {
      dispatch(resetHostDomain());
      disconnect();
    };
    // eslint-disable-next-line
  }, []);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      connect();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [handleVisibilityChange]);

  return (
    <div className="App">
      <PageBox />
    </div>
  );
}

export default App;
