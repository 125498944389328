// src/components/Header.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleTheme } from '../features/theme/themeSlice';
import ThemeToggle from './ThemeToggle';

const titles = {
  0: "Top up with crypto",
  1: "Amount",
  2: "Recipient",
  3: "Success",
};

const titlesGlobal = {
  0: "Buy USDP",
  1: "Buy USDP",
  2: "Buy USDP",
  3: "Buy USDP",
};

const Header = ({ index, onBackClick }) => {
  const isAllowedDomain = useSelector((state) => state.host?.isAllowedDomain);
  const theme = useSelector((state) => state.theme);
  const dispatch = useDispatch();

  const title = (isAllowedDomain ? titlesGlobal : titles)[index] || titles[0];
  const preventPrevious = [0, 3];

  return (
    <div className={`header ${theme}`}>
      {!preventPrevious.includes(index) && (
        <div className="back" onClick={onBackClick} />
      )}
      <ThemeToggle theme={theme} toggleTheme={() => dispatch(toggleTheme())} />
      <div className="header_title">{title}</div>
      <div className="logo" />
    </div>
  );
};

export default Header;
