import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moveToPage } from '../features/navigation/navigationSlice';
import { setCopied, setIsPaymentCompleted } from '../features/payment/paymentSlice';
import { updateStatus } from '../features/transaction/transactionSlice';
import { copyToClipboard } from '../utils/copyToClipboard';
import { getSearchParams, shortenWalletAddress } from '../utils/helpers';
import QRContainer from './QRContainer';
import StatusBar from './StatusBar';
import SwapTimer from './SwapTimer';

const Page3 = () => {
  const { wsData } = useSelector((state) => state.transaction);
  const isPaymentCompleted = useSelector((state) => state.payment.isPaymentCompleted);
  const status = useSelector((state) => state.transaction.status);
  const dispatch = useDispatch();
  const params = getSearchParams();
  const userWallet = params?.wa.toLowerCase();
  const copied = useSelector((state) => state.payment.copied);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const depositAddress = useSelector((state) => state.transaction.depositAddress);

  useEffect(() => {
    if (!wsData) {
      console.log("wsData is null or undefined");
      return;
    }

    console.log("Received wsData:", wsData);

    let parsedData;
    try {
      parsedData = JSON.parse(wsData);
      console.log("Parsed wsData:", parsedData);
    } catch (error) {
      console.error("Error parsing wsData:", error);
      return;
    }

    if (!parsedData) {
      console.log("parsedData is null or undefined");
      return;
    }

    console.log("parsedData Message:", parsedData);

    const { walletAddress, timestamp, status: wsStatus } = parsedData;

    if (!walletAddress || !timestamp || !wsStatus) {
      console.log("Missing required fields in parsedData:", { walletAddress, timestamp, wsStatus });
      return;
    }

    const messageTimestampInUTC = new Date(timestamp).getTime();
    const isWithinTimeLimit = Date.now() <= messageTimestampInUTC + 30 * 1000;

    if (userWallet === walletAddress.toLowerCase() && isWithinTimeLimit && ['funds_received', 'funds_sent_to_user', 'funds_received_by_user'].includes(wsStatus.toLowerCase())) {
      console.log("Conditions met, updating state");
      dispatch(setIsPaymentCompleted(true));
      dispatch(moveToPage(3));
      dispatch(updateStatus(3));
    } else {
      console.log("Conditions not met:", {
        userWallet,
        walletAddress: walletAddress.toLowerCase(),
        isWithinTimeLimit,
        wsStatus: wsStatus.toLowerCase(),
      });
    }
  }, [wsData, userWallet, dispatch]);

  const handleCopy = () => {
    copyToClipboard(depositAddress)
      .then((success) => {
        if (success) {
          console.log("Address copied to clipboard");
          dispatch(setCopied(true));
          setTimeout(() => {
            dispatch(setCopied(false));
          }, 1500);
        } else {
          console.error("Failed to copy address to clipboard");
        }
      });
  };

  return (
    <div className="page3">
      <StatusBar status={status} />
      <div className="page3_content">
        <div className="send-address-container">
          <label htmlFor="send-address" className="send-address-label">Send {selectedCoin.symbol.toLowerCase()} to this Address</label>
          <input className="send-address-input" id="send-address" type="text" value={shortenWalletAddress(depositAddress)} readOnly />
          <div className="tooltip">{depositAddress}</div>
          <div className={`icon ${copied ? 'copied' : ''}`} onClick={handleCopy} data-copied={copied.toString()} />
          <SwapTimer />
        </div>
        <QRContainer />
        <div className='recipient-container'>
          <label htmlFor="recipient" className="recipient-label">Recipient address</label>
          <div className="recipient-address">{userWallet}</div>
        </div>
        <button className={`button pink ${!isPaymentCompleted ? 'disabled' : ''}`} disabled={!isPaymentCompleted}>
          <span>Please complete payment</span>
        </button>
      </div>
    </div>
  );
};

export default Page3;
