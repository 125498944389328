// src/features/coins/coinsSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchData } from "../../utils/fetchData";
import { endpoints } from "../../utils/fetchUrls";
import { initialCoinsData } from "../../utils/initialCoinsData";

export const fetchCoins = createAsyncThunk("coins/fetchCoins", async () => {
  const response = await fetchData(endpoints.getCoinsList);
  return response.data.coins;
});

const coinsSlice = createSlice({
  name: "coins",
  initialState: {
    coins: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCoins.fulfilled, (state, action) => {
      const fetchedCoins = action.payload;


      let updatedCoinsData = initialCoinsData.map((coin) => {
        const fetchedCoin = fetchedCoins.find((c) => c.symbol === coin.name);

        if (fetchedCoin && fetchedCoin.depositEnabled === 1) {
          return { ...coin, ...fetchedCoin };
        }
        return null;
      });

      updatedCoinsData = updatedCoinsData.filter((coin) => coin !== null);
      state.coins = updatedCoinsData;
    });
    builder.addCase(fetchCoins.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default coinsSlice.reducer;
