// src/components/Page2.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calculateUSDP, setExchangeRate, setFee } from '../features/exchange/exchangeSlice';
import { setIsLoading, setRingStatuses } from '../features/loading/loadingSlice';
import { moveToPage } from '../features/navigation/navigationSlice';
import { setDepositAddress, setNetwork, updateStatus } from '../features/transaction/transactionSlice';
import { fetchData } from '../utils/fetchData';
import { endpoints } from '../utils/fetchUrls';
import ExchangeRate from './ExchangeRate';
import InputGroup from './InputGroup';
import SingleTransactionStatus from './SingleTransactionStatus';
import StatusBar from './StatusBar';

const Page2 = () => {
  const dispatch = useDispatch();
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  const amount = useSelector((state) => state.exchange.amount);
  const exchangeRate = useSelector((state) => state.exchange.exchangeRate);
  const fee = useSelector((state) => state.exchange.fee);
  const status = useSelector((state) => state.transaction.status);
  const isLoading = useSelector((state) => state.loading.isLoading);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);
  const searchParams = new URLSearchParams(window.location.search);
  const userWallet = searchParams.get('wa');
  const partnerRef = searchParams.get('pr');

  useEffect(() => {
    if (!selectedCoin) return;

    const getExchangeRate = () => {
      fetchData(`${endpoints.getExchangeRate}/${selectedCoin.nexusCurrencyId}`)
        .then((data) => {
          dispatch(setExchangeRate(data.data));
        })
        .catch((error) => console.error('Error:', error));
    };

    getExchangeRate();
    const timer = setInterval(getExchangeRate, 60000);
    return () => clearInterval(timer);
  }, [dispatch, selectedCoin]);

  useEffect(() => {
    if (selectedCoin) {
      dispatch(setFee(selectedCoin.feePercentage));
      dispatch(calculateUSDP());
    }
  }, [selectedCoin, amount, exchangeRate, fee, dispatch]);

  const handleContinue = () => {
    dispatch(setNetwork(selectedCoin.network));
    dispatch(setIsLoading(true));
    const fetchDataUrl = `${endpoints.getWalletForUser}/${userWallet}/${partnerRef}/${selectedCoin.nexusCurrencyId}`; // bridge
      fetchData(fetchDataUrl)
      .then((data) => {
        dispatch(setIsLoading(false));
        dispatch(setRingStatuses(true));
        dispatch(setDepositAddress(data.data));
        dispatch(updateStatus(2));
        dispatch(moveToPage(2));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.error('Error:', error);
      });
  };

  return (
    <div className="page2">
      <StatusBar status={status} />

      {isLoading && <SingleTransactionStatus ringStatuses={ringStatuses} />}

      <div className="page2_content">
        <InputGroup />
        <ExchangeRate onClick={handleContinue} />
      </div>
    </div>
  );
};

export default Page2;
