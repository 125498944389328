// src/components/IframePopup.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  hide_iframe_popup,
  set_iframe_popup,
} from "../features/iframe/iframeSlice";

const IframePopup = () => {
  const { src, type, visible } = useSelector((state) => state.iframe);
  const dispatch = useDispatch();

  const handleIframeMessage = (event) => {
    console.log(event, "event handleIframeMsg");
    const paybisUrl = "https://widget.paybis.com";
    if (event.origin === paybisUrl) {
      const data = JSON.parse(event.data);
      console.log(data, "__evt.data__");
      if (
        data?.payload?.state === "closed" ||
        data?.payload?.state === "[bp]:redirect"
      ) {
        dispatch(hide_iframe_popup());
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, []);

  if (!visible) return null;

  return (
    <div className="iframe-popup">
      <div className="iframe-popup-content">
        <iframe src={src} title={type} width="100%" height="600px" />
        <button onClick={() => dispatch(hide_iframe_popup())}>Close</button>
      </div>
    </div>
  );
};

export default IframePopup;
