// src/components/ExchangeRate.jsx
import React from 'react';
import { useSelector } from 'react-redux';

const ExchangeRate = ({ onClick }) => {
  const amount = useSelector((state) => state.exchange.amount);
  const exchangeRate = useSelector((state) => state.exchange.exchangeRate);
  const fee = useSelector((state) => state.exchange.fee);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  
  const isButtonDisabled = !amount || amount <= 0 || !exchangeRate || fee < 0 || !selectedCoin;

  return (
    <div className="exchange-rate">
      <div className="exchange-txt-row">
        <span className="exchange-rate-label">Exchange rate: 1 {selectedCoin.symbol} = {exchangeRate} USDP</span>
        <span className="fee">Fee: {fee}%</span>
      </div>
      <div className="funds-transferred">
        <div className="funds-txt-row">
          <div className="funds-icon"></div>
          <div className="funds-text">Funds transferred immediately</div>
        </div>
        <button className={`button blue ${isButtonDisabled ? 'disabled' : ''}`} disabled={isButtonDisabled} onClick={onClick}>
          <span>Continue</span>
        </button>
      </div>
    </div>
  );
};

export default ExchangeRate;
