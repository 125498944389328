// src/components/Page3Global.jsx
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moveToPage } from '../features/navigation/navigationSlice';
import { setIsPaymentCompleted, setCopied } from '../features/payment/paymentSlice';
import { setWsData, updateStatus } from '../features/transaction/transactionSlice';
import QRContainer from './QRContainer';
import { getSearchParams, shortenWalletAddress } from '../utils/helpers';
import { copyToClipboard } from '../utils/copyToClipboard';
import SwapTimer from './SwapTimer';

const Page3Global = () => {
  const { wsData } = useSelector((state) => state.transaction);
  const isPaymentCompleted = useSelector((state) => state.payment.isPaymentCompleted);
  const copied = useSelector((state) => state.payment.copied);
  const dispatch = useDispatch();
  const params = getSearchParams();
  const userWallet = params.wa;
  const userMagicAddress = useSelector((state) => state.host?.userMagicAddress);
  const selectedCoin = useSelector((state) => state.exchange.selectedCoin);
  
  const depositAddress = useSelector((state) => state.transaction.depositAddress);

  // Fake WebSocket data for testing with a timer
  /* useEffect(() => {
    if (!userMagicAddress) {
      return;
    }

    console.log('Fake WebSocket data for testing with a timer');
    const timer1 = setTimeout(() => {
      console.log('Fake WebSocket data 1');
      const fakeWsData1 = JSON.stringify({
        streamerMessage: {
          walletAddress: userMagicAddress,
          timestamp: new Date().toISOString(),
          status: 'funds_received',
          amount: '100.00 USDT'
        }
      });

      dispatch(setWsData(fakeWsData1));

      const timer2 = setTimeout(() => {
        console.log('Fake WebSocket data 2');
        const fakeWsData2 = JSON.stringify({
          streamerMessage: {
            walletAddress: userMagicAddress,
            timestamp: new Date().toISOString(),
            status: 'funds_received_by_user',
            amount: '100.00 USDT'
          }
        });

        dispatch(setWsData(fakeWsData2));
        console.log('Fake WebSocket data 2 dispatched');
        console.log('wsData:', wsData);
      }, 10000); // Additional 10 seconds

      return () => clearTimeout(timer2);
    }, 2000); // Initial 2 seconds

    return () => {
      clearTimeout(timer1);
      dispatch(setWsData(null));
    };
  }, [dispatch, userMagicAddress]); */
  // End of fake WebSocket data

  useEffect(() => {
    console.log('1');
    if (!wsData){
      console.log('2');
      return;
    }

    let parsedData;
    try {
      parsedData = JSON.parse(wsData);
    } catch (error) {
      console.log('3');
      return;
    }

    console.log('parsedData:', parsedData);
    // const streamerMessage = parsedData?.streamerMessage;
    // if (!streamerMessage){
    //   console.log('4');
    //   return;
    // }

    const { walletAddress, timestamp, status: wsStatus } = parsedData;
    if (!walletAddress || !timestamp || !wsStatus){
      console.log('5');
      return;
    }

    const messageTimestampInUTC = new Date(timestamp).getTime();
    const isWithinTimeLimit = Date.now() <= messageTimestampInUTC + 30 * 1000;
    //
    if (userMagicAddress.toLowerCase() === walletAddress.toLowerCase()){
      console.log('6');
    }
    if (!isWithinTimeLimit){
      console.log('7');
    }
    if (!['funds_received', 'funds_sent_to_user', 'funds_received_by_user'].includes(wsStatus.toLowerCase())){
      console.log('8');
    }
    //
    if (userMagicAddress.toLowerCase() === walletAddress.toLowerCase() && isWithinTimeLimit && ['funds_received', 'funds_sent_to_user', 'funds_received_by_user'].includes(wsStatus.toLowerCase())) {
      console.log('9');
      dispatch(setIsPaymentCompleted(true));
      dispatch(moveToPage(3));
      dispatch(updateStatus(3));
    }
  }, [wsData, userMagicAddress, dispatch]);

  const handleCopy = () => {
    copyToClipboard(depositAddress)
      .then((success) => {
        if (success) {
          dispatch(setCopied(true));
          setTimeout(() => {
            dispatch(setCopied(false));
          }, 1500);
        }
      });
  };

  return (
    <div className="page3">
      <div className="page3_content">
        <div className="send-address-container">
          <label htmlFor="send-address" className="send-address-label">Send {selectedCoin.symbol.toLowerCase()} to this Address</label>
          <input className="send-address-input" id="send-address" type="text" value={shortenWalletAddress(depositAddress)} readOnly />
          <div className="tooltip">{depositAddress}</div>
          <div className={`icon ${copied ? 'copied' : ''}`} onClick={handleCopy} data-copied={copied.toString()} />
          <SwapTimer />
        </div>
        <QRContainer />
        <div className='recipient-container'>
          <label htmlFor="recipient" className="recipient-label">Recpient address</label>
          <div className="recipient-address">{userWallet}</div>
        </div>
        <button className={`button blue ${!isPaymentCompleted ? 'disabled' : ''}`} disabled={!isPaymentCompleted}>
          <span>Please complete payment</span>
        </button>
      </div>
    </div>
  );
};

export default Page3Global;
