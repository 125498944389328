// src/components/ConfirmationPopup.js
import React from "react";
import { getSearchParams } from "../utils/helpers";

const ConfirmationPopup = ({ onClose, onContinue }) => {
  const params = getSearchParams();
  const walletAddress = params.wa;

  const handleContinue = () => {
    onContinue(walletAddress);
  };

  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup-content">
        <h1>Dear Customer:</h1>
        <p>
          Please note that you are buying USDT (tron) with Paybis, and it will
          be swapped automatically to USDP (Playblock) game tokens with
          coinexchange, for you to play. The swap can take a few minutes, and
          you will receive a confirmation mail by Coinsexchange.
        </p>
        <button onClick={handleContinue}>OK, continue</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
