// src/components/Page1.jsx
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCoin } from '../features/exchange/exchangeSlice';
import { moveToPage } from '../features/navigation/navigationSlice';
import Coin from './Coin';

const Page1 = () => {
  const coins = useSelector((state) => state.coins.coins);
  const error = useSelector((state) => state.coins.error);
  const dispatch = useDispatch();

  const handleClick = useCallback((selectedCoin) => {
    dispatch(setSelectedCoin(selectedCoin));
    dispatch(moveToPage(1));
  }, [dispatch]);

  if (error) {
    return <div className="error">There was an error: {error}</div>;
  }

  if (!coins.length) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="page1">
      {coins.map((coin) => (
        <Coin key={coin.id} coin={coin} onClick={() => handleClick(coin)} />
      ))}
    </div>
  );
};

export default Page1;
