// src/components/StatusBar.jsx
import React from 'react';
import { useSelector } from 'react-redux';
import useRenderCount from '../hooks/useRenderCount';

const StatusBar = () => {
  // useRenderCount("StatusBar");
  const status = useSelector((state) => state.transaction.status);

  const statusItems = [
    { color: 'blue', text: 'Amount', status: 1 },
    { color: 'pink', text: 'Recipient', status: 2 },
    { color: 'orange', text: 'Status', status: 3 },
  ];

  return (
    <div className="status-bar">
      {statusItems.map((item, index) => (
        <StatusItem key={item.status} color={item.color} status={item.status} currentStatus={status} text={item.text} isFirst={index === 0} />
      ))}
    </div>
  );
};

const StatusItem = ({ color, status, currentStatus, text, isFirst }) => {
  // useRenderCount("StatusItem");
  return(
    <div className={`status-item ${currentStatus >= status ? color : 'gray'}`}>
      {isFirst && <div className="status-circle" />}
      <div className="status-line" />
      <div className="status-circle" />
      <div className={`status-description ${currentStatus >= status ? color : 'gray'}`}>{text}</div>
    </div>
  );
};
export default StatusBar;