// src/components/InputGroup.jsx
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import usdpIcon from '../Images/USDP.png';
import { setAmount } from '../features/exchange/exchangeSlice';
import CurrencySelector from './CurrencySelector';

const InputGroup = () => {
  const dispatch = useDispatch();
  const usdpAmount = useSelector((state) => state.exchange.usdpAmount);

  const handleAmountChange = (event) => {
    dispatch(setAmount(event.target.value));
  };

  return (
    <div className="input-group">
      <div className="input-container">
        <label htmlFor="input1" className="input-label">You send</label>
        <div className="currency_input_wrap">
          <input className="input" id="input1" type="number" inputMode="numeric" pattern="[0-9]*" min="0" placeholder="Enter Amount" onChange={handleAmountChange} />
          <CurrencySelector />
        </div>
      </div>
      <div className="input-container">
        <label htmlFor="input2" className="input-label">You receive (approximately) ≈</label>
        <div className="currency_input_wrap">
          <input className="input" id="input2" type="number" min="0" value={usdpAmount} readOnly />
          <div className="usdp-container">
            <img className="usdp-icon" src={usdpIcon} alt="USDP icon" />
            <span className="currency-name">USDP</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InputGroup;
