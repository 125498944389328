// src/components/Page4Global.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const Page4Global = () => {
  console.log('Rendering Page4Global');
  const wsData = useSelector((state) => state.transaction.wsData);
  const userMagicAddress = useSelector((state) => state.host?.userMagicAddress);
  const [ringStatuses, setRingStatuses] = useState([false, false, false]);

  useEffect(() => {
    if (wsData) {
      // console.log('wsData received:', wsData);
      const messageData = JSON.parse(wsData);
      // console.log('Message data:', messageData);
      // const { streamerMessage } = messageData;

      if (!userMagicAddress || userMagicAddress.toLowerCase() !== messageData.walletAddress.toLowerCase()) {
        // console.log('User magic address does not match');
        return;
      }

      if (messageData) {
        // console.log('Streamer message received:', streamerMessage);
        const newStatus = messageData.status.toLowerCase();
        setRingStatuses((prevStatuses) => {
          // console.log('Previous ring statuses:', prevStatuses);
          let updatedStatuses;
          switch (newStatus) {
            case 'funds_received':
              updatedStatuses = [true, false, false];
              break;
            case 'funds_sent_to_user':
              updatedStatuses = [true, true, false];
              break;
            case 'funds_received_by_user':
              updatedStatuses = [true, true, true];
              break;
            default:
              updatedStatuses = prevStatuses;
          }
          // console.log('Updated ring statuses:', updatedStatuses);
          return updatedStatuses;
        });
      }
    }
  }, [wsData, userMagicAddress]);

  return (
    <div className="page4Global">
      <TransactionStatus ringStatuses={ringStatuses} />
    </div>
  );
};

export const TransactionStatus = ({ ringStatuses }) => {
  const texts = ["Transaction in progress", "Transaction arrived", "Transaction complete"];
  // console.log('Rendering TransactionStatus with ringStatuses:', ringStatuses);
  return (
    <div className="container">
      {ringStatuses.map((ringStatus, i) => (
        <div key={i} className="item">
          <div className="top">
            <div className={`ring ${ringStatus ? 'full' : ''}`}>
              <svg height="200" width="200">
                <circle className={`circle ${ringStatus ? '' : 'loading'}`} cx="100" cy="100" r="40" stroke="#fe9725" strokeWidth="6" fillOpacity="0" />
              </svg>
              <div className="image" />
            </div>
          </div>
          <div className="bottom">
            <div className="text">{texts[i]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Page4Global;
