// src/components/Page1Global.js
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedCoin } from '../features/exchange/exchangeSlice';
import { moveToPage } from '../features/navigation/navigationSlice';
import Coin from './Coin';
import ConfirmationPopup from './ConfirmationPopup';
import IframePopup from './IframePopup';
import { getPaybisUrl } from '../utils/getPaybisUrl';
import { buyCoin } from '../utils/buyCoin';
import SingleTransactionStatus from './SingleTransactionStatus';

const Page1Global = () => {
  const coins = useSelector((state) => state.coins.coins);
  const error = useSelector((state) => state.coins.error);
  const { visible: iframeVisible } = useSelector((state) => state.iframe);
  const userMagicAddress = useSelector((state) => state.host?.userMagicAddress);
  const dispatch = useDispatch();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [buyMethod, setBuyMethod] = useState(null);
  const ringStatuses = useSelector((state) => state.loading.ringStatuses);

  const handleClick = useCallback((selectedCoin) => {
    dispatch(setSelectedCoin(selectedCoin));
    dispatch(moveToPage(1));
  }, [dispatch]);

  const handleBuyCoinClick = async (method) => {
    const walletAddress = new URLSearchParams(window.location.search).get('wa');
    if (method === 'bank') {
      buyCoin(walletAddress, dispatch);
    } else {
      setBuyMethod(method);
      setShowConfirmation(true);
    }
  };

  const closeConfirmation = () => {
    setShowConfirmation(false);
    setBuyMethod(null);
  };

  const handleContinue = async (walletAddress) => {
    if (buyMethod === 'card') {
      await getPaybisUrl(walletAddress, userMagicAddress, dispatch);
    }
    setShowConfirmation(false);
  };

  if (error) {
    return <div className="error">There was an error: {error}</div>;
  }

  if (!coins.length) {
    return(
        <div className="page1 global">
          <SingleTransactionStatus ringStatuses={ringStatuses} />
        </div>
    );
  }

  return (
    <div className="page1">
      {!showConfirmation && !iframeVisible && coins.map((coin) => (
        <Coin key={coin.id} coin={coin} onClick={() => handleClick(coin)} />
      ))}
      {!showConfirmation && !iframeVisible && (
        <>
          <button className="buy-coin-button" onClick={() => handleBuyCoinClick('bank')}>
            Buy with bank transfer
            <div className="buy-coin-button-icon"/>
          </button>
          <button className="buy-card-button" onClick={() => handleBuyCoinClick('card')}>
            Buy with credit/debit card
            <div className="buy-card-button-icons">
              <div className="visa-icon"/>
              <div className="mastercard-icon"/>
            </div>
          </button>
        </>
      )}
      {showConfirmation && <ConfirmationPopup onClose={closeConfirmation} onContinue={handleContinue} />}
      <IframePopup />
    </div>
  );
};

export default Page1Global;
