// src/components/Page4.jsx
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatusBar from './StatusBar';
import { setWsData } from '../features/transaction/transactionSlice';
const Page4 = () => {
  const wsData = useSelector((state) => state.transaction.wsData);
  const transactionStatuses = useSelector((state) => state.transactionStatus.statuses);
  const [ringStatuses, setRingStatuses] = useState([false, false, false]);
  const [amount, setAmount] = useState(null);
  const [isTransactionCompleted, setIsTransactionCompleted] = useState(false);
  const status = useSelector((state) => state.transaction.status);
  const searchParams = new URLSearchParams(window.location.search);
  const userWallet = searchParams.get('wa');
  const dispatch = useDispatch();

  // Fake WebSocket data for testing with a timer
  //   useEffect(() => {
  //   const timer1 = setTimeout(() => {
  //     const fakeWsData1 = JSON.stringify({
  //         walletAddress: userWallet,
  //         timestamp: new Date().toISOString(),
  //         status: 'funds_sent_to_user',
  //         amount: '100.00 USDT'
  //     });

  //     dispatch(setWsData(fakeWsData1));

  //     const timer2 = setTimeout(() => {
  //       const fakeWsData2 = JSON.stringify({
  //           walletAddress: userWallet,
  //           timestamp: new Date().toISOString(),
  //           status: 'funds_received_by_user',
  //           amount: '100.00 USDT'
  //       });

  //       dispatch(setWsData(fakeWsData2));
  //     }, 3000); // Additional 3 seconds

  //     return () => clearTimeout(timer2);
  //   }, 3000); // 3 seconds

  //   return () => {
  //     clearTimeout(timer1);
  //     dispatch(setWsData(null));
  //   };
  // }, [dispatch, userWallet]);
  // End of fake WebSocket data

  useEffect(() => {
    if (wsData) {
      const messageData = JSON.parse(wsData);
      console.log('Message data:', messageData);

      if (!userWallet || userWallet !== messageData.walletAddress) {
        console.log('User wallet address does not match');
        return;
      }

      if (messageData) {
        console.log('transactionStatuses, messageData.status:', transactionStatuses, messageData.status);
        const newStatus = messageData.status.toLowerCase();
        setRingStatuses((prevStatuses) => {
          let updatedStatuses;
          switch (newStatus) {
            case 'funds_received':
              updatedStatuses = [true, false, false];
              break;
            case 'funds_sent_to_user':
              updatedStatuses = [true, true, false];
              break;
            case 'funds_received_by_user':
              updatedStatuses = [true, true, true];
              break;
            default:
              updatedStatuses = prevStatuses;
          }
          return updatedStatuses;
        });

        if (messageData.status !== 'funds_received') {
          setAmount(messageData.amount);
        }

        if (messageData.status === 'funds_received_by_user') {
          setIsTransactionCompleted(true);
        }
      }
    }
  }, [wsData, userWallet, transactionStatuses]);

  return (
    <div className="page4">
      <StatusBar status={status} />
      <TransactionStatus ringStatuses={ringStatuses} />
      <AmountSent amount={amount} />
      <button className={`button green ${!isTransactionCompleted ? 'disabled' : ''}`} disabled={!isTransactionCompleted} onClick={() => window.parent.postMessage('closeIframe', '*')}>
        <span>{!isTransactionCompleted ? 'Transaction In Progress' : 'Transaction was successful'}</span>
      </button>
    </div>
  );
};

const TransactionStatus = ({ ringStatuses }) => {
  const texts = ["Transaction arrived", "Exchanging", "Transaction complete"];
  return (
    <div className="container">
      {ringStatuses.map((ringStatus, i) => (
        <div key={i} className="item">
          <div className="top">
            <div className={`ring ${ringStatus ? 'full' : ''}`}>
              <svg height="200" width="200">
                <circle className={`circle ${ringStatus ? '' : 'loading'}`} cx="100" cy="100" r="40" stroke="#fe9725" strokeWidth="6" fillOpacity="0" />
              </svg>
              <div className="image" />
            </div>
          </div>
          <div className="bottom">
            <div className="text">{texts[i]}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

const AmountSent = ({ amount }) => (
  <div className="amount-sent-container">
    <span className="usdp-text">Amount was sent to Your wallet:</span>
    <div className="usdp-info">
      {amount && (
        <>
          <div className="usdp-icon" />
          <span className="usdp-amount">{amount}</span>
        </>
      )}
    </div>
  </div>
);

export default Page4;
